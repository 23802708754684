import React from 'react';
import PropTypes from 'prop-types';

const Opcao = (props) => {
    const trava = (props.idEscolhida === -1)? false : true;
    return (
        <li className="opcao">
            <input
                type="radio"
                className="radioCustomButton"
                name="radioGroup"
                checked={props.indice === props.idEscolhida}
                id={props.indice}
                value={props.indice}
                disabled={trava}
                onChange={props.onAnswerSelected}
            />
            <label className="radioCustomLabel" htmlFor={props.indice}>
                <p className="opcao-texto">{props.resposta}</p>
            </label>
        </li>
    );
}

Opcao.propTypes = {
    indice: PropTypes.number.isRequired,
    idEscolhida: PropTypes.number.isRequired,
    resposta: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func.isRequired
};

export default Opcao;