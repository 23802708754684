import React from 'react';

import{
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon
} from "react-share";

const shareUrl = window.location.href;
const title = 'Corona Quiz';

const Share = (props) => {
    return (
        <div className="share-container-buttons">
            <FacebookShareButton
              url={shareUrl}
              quote={title}>
                  <FacebookIcon size={48} round/>
              </FacebookShareButton>

              <TwitterShareButton
              url={shareUrl}
              quote={title}>
                  <TwitterIcon size={48} round/>
              </TwitterShareButton>

              <WhatsappShareButton
              url={shareUrl}
              quote={title}>
                  <WhatsappIcon size={48} round/>
              </WhatsappShareButton>

              <TelegramShareButton
              url={shareUrl}
              quote={title}>
                  <TelegramIcon size={48} round/>
              </TelegramShareButton>  

        </div>
    )
}

export {shareUrl, Share};