import React from 'react';
// import HamburgerMenu from 'react-hamburger-menu';



/* image imports */
//import bg from '../img/bg.png';
import logo0 from '../img/logo0.png';
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
import logo4 from '../img/logo4.png';
import logo5 from '../img/logo5.png';


const Creds = (props) => {
  
    return (
      <section className="creds">
            <button onClick={props.voltar} className="sobre-button">VOLTAR</button>
            <div style={{clear: 'borh'}}/>
            
            <p className="credits-header">Realização:</p> <br />
            <p>
                <strong>Programa de Pós-Graduação em Mídias Criativas da Escola de Comunicação - UFRJ.
                Linha Editorial.</strong> <br />
                Primeiro mestrado profissional em criação e produção de conteúdos digitais credenciado pela CAPES/MEC implantado numa instituição pública de Ensino Superior no Brasil. <br />
                <strong>Contato:</strong> https://ppgmc.eco.ufrj.br <br />
                <strong>Webmail:</strong> secretaria.ppgmc@eco.ufrj.br <br />
            </p>
            <p style={{marginTop: "2em"}}>
              <b className="right-bold">Supervisão:</b> Octavio Aragão. <br />
              <b className="right-bold">Design:</b> André Flauzino, Fábio Marinho, Isabelle Barreto. <br />
              <b className="right-bold">Dinâmica do jogo:</b> Isabelle Barreto. <br />
              <b className="right-bold">Texto:</b> Creuza Gravina, Itala Barros, Gilberto Scofield Jr., Luciana Avelar.
            </p> 

            <p style={{marginTop: "2em"}}>
              <b className="right-bold">
                Equipe de Implementação
              </b> <br />
              <b>Coordenador:</b> Geraldo Xexéo <br></br>
              <b>Código:</b> Eduardo Mangeli e Henrique Vermelho
            </p>

            <img className="logo-credit tinier" src={logo1} alt="ppgmc">
            </img>
            <img className="logo-credit" src={logo2} alt="eco">
            </img>
            <img className="logo-credit" src={logo4} alt="ludes">
            </img>
            <img className="logo-credit tinier logo-pesc" src={logo5} alt="pesc">
            </img>
            <img className="logo-credit" src={logo0} alt="coppe">
            </img>
            <img className="logo-credit" src={logo3} alt="ufrj">
            </img>
        </section>
      )
};

export default Creds;