import React from 'react';
import PropTypes from 'prop-types';
import playAgainButton from '../img/play_again_button.png';
import acertos0 from '../img/acertos0.png';
import acertos1 from '../img/acertos1.png';
import acertos2 from '../img/acertos2.png';
import acertos3 from '../img/acertos3.png';
import acertos4 from '../img/acertos4.png';
import gota from "../img/gota_pequena.png";

import {Share, shareUrl} from './Share';

import {FacebookShareButton} from "react-share";


const Resultado = props => {

    const num_certas = props.num_certas;

    const imagem_acertos = (acertos) => {
        let retorno;
        switch (acertos) {
            case 0:
                retorno = acertos0;
                break;
            case 1:
                retorno = acertos1;
                break;
            case 2:
                retorno = acertos2;
                break;
            case 3:
                retorno = acertos3;
                break;
            case 4:
                retorno = acertos4;
                break;
            default:
                break;
        }
        return retorno;
    }

    const texto_acertos = (acertos) => {
        let texto;
        switch (acertos) {
            case 0:
                texto = (<div>
                    <p className="acertos">ZERO ACERTOS</p>
                    <p className="centralizado home-text">
                        Se liga! <br/>
                        Aprenda mais.<br/>
                        <span className="texto-verde">Vamos jogar de novo?</span><br/>
                    </p></div>);
                break;
            case 1:
                texto = (<div>
                    <p className="acertos">1 ACERTO</p>
                    <p className="centralizado home-text">
                        Aprenda a se proteger!<br />
                        Não apagou o Coronavírus.<br />
                        <span className="texto-verde">Jogue de novo!</span><br/>
                    </p></div>);
                break;
            case 2:
                texto = (<div>
                    <p className="acertos">2 ACERTOS</p>
                    <p className="centralizado home-text">
                        Venha aprender mais!<br/>
                        Não apagou tudo.<br/>
                    <span className="texto-verde">Jogue de novo!</span><br/>
                    </p></div>);
                break;
            case 3:
                texto = (<div><p className="acertos">3 ACERTOS</p>
                    <p className="centralizado home-text">
                        Quase lá!<br/>
                        Faltou pouco para apagar.<br/>
                    <span className="texto-verde">Vamos! Repita o jogo.</span><br/> 
                    </p></div>);
                break;
            case 4:
                texto = (<div><p className="acertos">4 ACERTOS</p>
                    <p className="centralizado home-text">
                        Parabéns!<br/>
                        Apagou o Coronavírus. <br/>
                        <span className="texto-verde">Espalhe essa ideia.</span><br/>
                    </p></div>);
                break;
            default:
                break;
        }
        return texto;
    }


    return(
        <div className="resultado">
            <img className="acerto-img" src={imagem_acertos(num_certas)} alt="1 acerto" /> 
            <p style={{paddingTop: '1em', color: 'red', fontWeight: 'bold'}}>{ props.estourou? 'Você estourou o tempo!' : ''}</p>
            {texto_acertos(num_certas)}
            <img className="button" src={playAgainButton} 
                    onClick={() => window.location.reload(false)} 
                    alt="Jogar Novamente">
            </img>
            <br />
            <div className="share-container">
                <p className="compartilhe-text">Compartilhe o jogo</p>  
                <FacebookShareButton url={shareUrl}>
                    <img className="gota" src={gota} alt="gota"></img>
                </FacebookShareButton> 
            </div>
            <Share />
        </div>
    )

}

Resultado.propTypes = {
    num_certas: PropTypes.number.isRequired,
    sobre: PropTypes.func.isRequired,
    estourou: PropTypes.bool.isRequired
}

export default Resultado;