import React from 'react';
import PropTypes from 'prop-types';
import nextButton from '../img/next_button.png';
import ProgressBar from 'react-bootstrap/ProgressBar'


const Comentario = (props) => {
    return (
        <div className="comentario">
            <div className="comentario-num-wrapper">
                <span className="comentario-num-left">{`${props.num_certas}`}</span>
                <span className="comentario-num-right">{`${props.num_certas}/${props.total}`}</span>
            </div>
        
            <ProgressBar className="progresso" max={4} now={props.num_certas} />

        { props.certa ? 
              <h5 className="centralizado comentario-header texto-verde">Parabéns, você acertou!</h5> : 
              <h5 className="centralizado comentario-header texto-vermelho">Cuidado, você errou!</h5> }
        
                <p className="centralizado comentario-texto">
                    {props.comentario}
                </p>
                <p className="centralizado comentario-texto">
                    Fonte: {props.fonte}
                </p>
                <img className="button" src={nextButton} onClick={props.proxima} alt="Próxima" />
            </div>
        );
    
}

Comentario.propTypes = {
    comentario: PropTypes.string.isRequired,
    proxima: PropTypes.func.isRequired,
    fonte: PropTypes.string.isRequired
}

export default Comentario;