import React from 'react';
import PropTypes from 'prop-types';
//import QuestionCount from './QuestionCount';
import Opcao from './Opcao';

const Pergunta = (props) => {
    const renderOpcoes = (opcao, indice) => {
        return(
            <Opcao
                key={opcao.resposta}
                indice={indice}
                certa={opcao.certa}
                comentario={opcao.comentario}
                resposta={opcao.resposta}
                idEscolhida={props.idEscolhida}
                onAnswerSelected={props.onAnswerSelected}
            />
        );
    }

    return(
        <div className="pergunta">
            {/*
            <QuestionCount 
                counter={props.num_pergunta}
                total={props.questionTotal}
            />
             */}
            <p className="pergunta-header">{props.pergunta}</p>
            <ul className="opcoes">
                {props.opcoes.map(renderOpcoes)}
            </ul>
        </div>
    );
}


Pergunta.propTypes = {
    opcoes: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    num_pergunta: PropTypes.number.isRequired,
    idEscolhida: PropTypes.number.isRequired,
    //certa: PropTypes.bool.isRequired,
    //resposta: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
    pergunta: PropTypes.string.isRequired
  };
  
export default Pergunta;

