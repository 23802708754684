export const coronaQuestions = [
   {
      pergunta:"Qual lugar fora de casa é possível ter menos risco de contaminação de Covid-19?",
      opcoes:[
         {
            resposta:"Em uma festa com as portas e janelas fechadas.",
            comentario:"Evite. Quanto menos circulação de ar tiver dentro de um ambiente, maior são as chances do vírus se espalhar",
            certa:false
         },
         {
            resposta:"Em um ambiente aberto, mantendo a distância mínima de 2 metros.",
            comentario:"Isso aí! O ar livre é mais seguro, pois com a distância e a circulação de ar, as chances de contágio diminuem.",
            certa:true
         },
         {
            resposta:"Na igreja.",
            comentario:"Devemos evitar ficar no mesmo lugar durante muito tempo, pois a permanência pode ajudar no contágio.",
            certa:false
         },
         {
            resposta:"Transporte público.",
            comentario:"Mesmo com máscara, evite aglomerações.",
            certa:false
         }
      ],
      fonte:"​JAMA Health Forum"
   },
   {
      pergunta:"De quanto em quanto tempo você deve limpar as mãos com álcool em gel?",
      opcoes:[
         {
            resposta:"Toda vez que tocar em algo.",
            comentario:"Certo! Para diminuir o risco de ficar doente, deixe as mãos limpas sempre que tocar em algo.",
            certa:true
         },
         {
            resposta:"Sempre que espirrar.",
            comentario:"Se estiver de máscara, não precisa tirar para passar álcool. Mas é preciso limpar a mão ao encostar em algo.",
            certa:false
         },
         {
            resposta:"Antes de encostar na boca.",
            comentario:"Sempre que estiver na rua, não encoste na boca.",
            certa:false
         },
         {
            resposta:"Sempre que for ao banheiro.",
            comentario:"Use o banheiro para fazer uma limpeza mais profunda nas mãos, com água e sabão.",
            certa:false
         }
      ],
      fonte:"​Organização Pan-Americana da Saúde"
   },
   {
      pergunta:"Durante quanto tempo você pode usar a mesma máscara?",
      opcoes:[
         {
            resposta:"1 hora.",
            comentario:"O tempo máximo com a máscara é de 3 horas, caso não esteja úmida. Nesse caso, é preciso trocá-la. Se tirá-la, não reponha.",
            certa:false
         },
         {
            resposta:"3 horas.",
            comentario:"Parabéns, você acertou, m​as atenção, se ela estiver úmida, é preciso trocá-la. Se tirá-la, não reponha.",
            certa:true
         },
         {
            resposta:"12 horas.",
            comentario:"Errou! O tempo máximo com uma máscara é de 3 horas.",
            certa:false
         },
         {
            resposta:"24 horas.",
            comentario:"Errou! O tempo máximo com uma máscara é de 3 horas.",
            certa:false
         }
      ],
      fonte:"Anvisa"
   },
   {
      pergunta:"O que eu posso fazer enquanto estiver usando máscara?",
      opcoes:[
         {
            resposta:"Coçar o nariz por cima do tecido.",
            comentario:"Ao colocar a mão por cima da máscara, você aumenta o risco de contaminação.",
            certa:false
         },
         {
            resposta:"Tirar quando não tiver ninguém perto para dar uma respirada e depois colocar de novo.",
            comentario:"A máscara não pode ser tirada e depois colocada de novo. Se você tirou a máscara, precisa colocar uma nova.",
            certa:false
         },
         {
            resposta:"Usar apenas quando for entrar em uma loja.",
            comentario:"A máscara tem que ser usada sempre ao sair de casa.",
            certa:false
         },
         {
            resposta:"Espirrar.",
            comentario:"Tudo bem espirrar estando de máscara!",
            certa:true
         }
      ],
      fonte:"​OMS"
   },
   {
      pergunta:"​O que é o novo coronavírus?",
      opcoes:[
         {
            resposta:"Um vírus",
            comentario:"​Os coronavírus são uma grande família de vírus comuns em muitas espécies diferentes de animais.",
            certa:true
         },
         {
            resposta:"Uma bactéria",
            comentario:"Não. O coronavírus, como o nome indica, é uma família de vírus",
            certa:false
         },
         {
            resposta:"Um tipo de Gripe",
            comentario:"Não. O coronavírus, como o nome indica, é uma família de vírus",
            certa:false
         },
         {
            resposta:"Uma Infecção Sexualmente Transmissível",
            comentario:"Não. O coronavírus, como o nome indica, é uma família de vírus",
            certa:false
         }
      ],
      fonte:"​Ministério da Saúde"
   },
   {
      pergunta:"Covid-19 e o novo coronavírus são a mesma coisa?",
      opcoes:[
         {
            resposta:"Sim, são a mesma doença que veio no macaco.",
            comentario:"​COVID-19 é a doença infecciosa causada pelo novo coronavírus (SARS-CoV-2).",
            certa:false
         },
         {
            resposta:"Não, um só dá em morcego e o outro em humanos.",
            comentario:"​COVID-19 é a doença infecciosa causada pelo novo coronavírus (SARS-CoV-2).",
            certa:false
         },
         {
            resposta:"Sim, só muda o jeito que os médicos falam.",
            comentario:"​COVID-19 é a doença infecciosa causada pelo novo coronavírus (SARS-CoV-2)​.",
            certa:false
         },
         {
            resposta:"Não.",
            comentario:"​COVID-19 é a doença infecciosa causada pelo novo coronavírus (SARS-CoV-2).",
            certa:true
         }
      ],
      fonte:"​Ministério da Saúde"
   },
   {
      pergunta:"Hidroxicloroquina é remédio para a Covid-19?",
      opcoes:[
         {
            resposta:"Sim, e deve ser tomado com ivermectina.",
            comentario:"Não há eficácia comprovada no uso nem da ivermectina nem da Hidroxicloroquina.",
            certa:false
         },
         {
            resposta:"Não, pois ainda não comprovaram isso.",
            comentario:"Correto! Apesar de muitos estudos, nenhum comprovou que o uso do medicamento cura a Covid-19.",
            certa:true
         },
         {
            resposta:"Sim, é o único remédio que os médicos recomendam.",
            comentario:"Existe muito debate sobre o uso da Hidroxicloroquina.",
            certa:false
         },
         {
            resposta:"Não, a única coisa que cura é vitamina C.",
            comentario:"Vitamina C pode ajudar a fortalecer seu sistema imunológico, mas não cura Covid-19.",
            certa:false
         }
      ],
      fonte: "Sociedade Brasileira de Imunologia"
   },
   {
      pergunta:"O vírus sobrevive quanto tempo em superfícies?",
      opcoes:[
         {
            resposta:"1 hora.",
            comentario:"Ainda não se sabe quanto tempo ele pode durar, depende da superfície. Sempre limpe tudo com álcool.",
            certa:false
         },
         {
            resposta:"1 dia.",
            comentario:"Ainda não se sabe quanto tempo ele pode durar, depende da superfície. Sempre limpe tudo com álcool.",
            certa:false
         },
         {
            resposta:"Não se sabe ainda.",
            comentario:"Correto! Estudos sugerem que ele pode ficar lá durante horas ou dias, dependendo do lugar.",
            certa:true
         },
         {
            resposta:"12 horas.",
            comentario:"Ainda não se sabe quanto tempo ele pode durar, depende da superfície. Sempre limpe tudo com álcool.",
            certa:false
         }
      ],
      fonte:"Fiocruz"
   },
   {
      pergunta:"Existe vacina contra a Covid-19?",
      opcoes:[
         {
            resposta:"Sim, feita por chineses.",
            comentario:"A China está trabalhando em uma vacina, mas ainda não foi aprovada pela OMS.",
            certa:false
         },
         {
            resposta:"Sim, feita pela Rússia.",
            comentario:"A Rússia está trabalhando em uma vacina, mas ainda não foi aprovada pela OMS.",
            certa:false
         },
         {
            resposta:"Vários países estão trabalhando, mas nenhuma é oficial.",
            comentario:"Certo! Até o momento não há vacina, mas vários países estão trabalhando juntos para criar uma segura.",
            certa:true
         },
         {
            resposta:"Não, é só tomar cloroquina.",
            comentario:"Errou. Nenhum estudo comprova a eficácia da hidroxicloroquina.",
            certa:false
         }
      ],
      fonte:"Fiocruz"
   },
   {
      pergunta:"​Existe tratamento contra o novo coronavírus?",
      opcoes:[
         {
            resposta:"Sim, vitamina C e Zinco.",
            comentario:"Não existe uma medicação específica para o vírus.",
            certa:false
         },
         {
            resposta:"Sim, Cloroquina.",
            comentario:"Não existe uma medicação específica para o vírus.",
            certa:false
         },
         {
            resposta:"Sim, Cloroquina e Ivermectina.",
            comentario:"Não existe uma medicação específica para o vírus.",
            certa:false
         },
         {
            resposta:"Não, ainda não foi criado um medicamento específico.",
            comentario:"Certo. Não há medicação, mas cientistas do mundo todo estão trabalhando nisso.",
            certa:true
         }
      ],
      fonte:"Fiocruz"
   },
   {
      pergunta:"O que aumenta a chance de contágio do Covid-19?",
      opcoes:[
         {
            resposta:"Abraçar alguém.",
            comentario:"Isso mesmo! O contato físico ajuda na propagação do vírus.",
            certa:true
         },
         {
            resposta:"Vacina de Gripe.",
            comentario:"​A vacina da gripe não tem qualquer relação com o novo coronavírus.",
            certa:false
         },
         {
            resposta:"Máscaras chinesas.",
            comentario:"Independente de onde as máscaras foram feitas, elas devem ser lavadas antes do uso.",
            certa:false
         },
         {
            resposta:"Álcool em gel.",
            comentario:"O álcool em gel ajuda a higienizar mãos e objetos e, assim, reduzir o risco de contágio.",
            certa:false
         }
      ],
      fonte:"​Ministério da Saúde"
   },
   {
      pergunta:"O que devo fazer estiver com sintomas leves de COVID-19?",
      opcoes:[
         {
            resposta:"Adote o isolamento domiciliar e monitoramento da saúde.",
            comentario:"Adote o uso de máscara, higienize as mãos e não compartilhe objetos.",
            certa:true
         },
         {
            resposta:"Ir ao posto de saúde.",
            comentario:"Se os sintomas forem leves, faça isolamento domiciliar e monitoramento de saúde.",
            certa:false
         },
         {
            resposta:"Ir ao consultório médico.",
            comentario:"Se os sintomas forem leves, faça isolamento domiciliar e monitoramento de saúde.",
            certa:false
         },
         {
            resposta:"Somente esperar.",
            comentario:"Se os sintomas forem leves, faça isolamento domiciliar e monitoramento de saúde.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Se tenho sintomas de Covid, quando devo procurar atendimento médico?",
      opcoes:[
         {
            resposta:"Se tiver febre intermitente, dificuldade de respirar ou dor/pressão no peito.",
            comentario:"Se possível, ligue para o seu médico para que ele possa direcioná-lo para o centro de saúde certo.",
            certa:true
         },
         {
            resposta:"Quando começar a tossir.",
            comentario:"Se você sentir febre intermitente, dificuldade de respirar ou dor no peito, procure um médico.",
            certa:false
         },
         {
            resposta:"Logo no primeiro espirro.",
            comentario:"Se você sentir febre intermitente, dificuldade de respirar ou dor no peito, procure um médico.",
            certa:false
         },
         {
            resposta:"Somente ficar em isolamento domiciliar.",
            comentario:"Se você sentir febre intermitente, dificuldade de respirar ou dor no peito, procure um médico.",
            certa:false
         }
      ],
      fonte:"​https://coronavirus.saude.gov.br/sobre-a-doenca#se-eu-ficar-doente"
   },
   {
      pergunta:"Quanto tempo o vírus leva para se manifestar?",
      opcoes:[
         {
            resposta:"Varia de 1 a 14 dias.",
            comentario:"Ele pode até não se manifestar, mas geralmente ocorre em torno de 5 dias.",
            certa:true
         },
         {
            resposta:"Não se sabe.",
            comentario:"Geralmente ocorre de 1 a 14 dias mas assintomáticos também transmitem a doença.",
            certa:false
         },
         {
            resposta:"Na mesma hora.",
            comentario:"Geralmente ocorre de 1 a 14 dias mas assintomáticos também transmitem a doença.",
            certa:false
         },
         {
            resposta:"Não irá se manifestar.",
            comentario:"Apenas alguns pacientes ficam assintomáticos, mas transmitem a doença.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"O cozimento ou o aquecimento dos alimentos elimina o novo coronavírus?",
      opcoes:[
         {
            resposta:"Não.",
            comentario:"Alimentos devem ser bem cozidos e/ou aquecidos a pelo menos 70°C.",
            certa:false
         },
         {
            resposta:"Sim.",
            comentario:"Alimentos devem ser bem cozidos e/ou aquecidos a pelo menos 70°C.",
            certa:true
         },
         {
            resposta:"Apenas alguns.",
            comentario:"Alimentos devem ser bem cozidos e/ou aquecidos a pelo menos 70°C.",
            certa:false
         },
         {
            resposta:"A temperatura ambiente é o ideal.",
            comentario:"Alimentos devem ser bem cozidos e/ou aquecidos a pelo menos 70°C.",
            certa:false
         }
      ],
      fonte:"​Fiocruz​ ​https://portal.fiocruz.br/pergunta/ferver-comida-mata-o-coronavirus"
   },
   {
      pergunta:"Como higienizar roupas e calçados vindos da rua?",
      opcoes:[
         {
            resposta:"Lavando com água e sabão e deixando o calçado fora de casa.",
            comentario:"O melhor é separar a roupa suja se ela não puder ser lavada imediatamente.",
            certa:true
         },
         {
            resposta:"Colocar para arejar no sol.",
            comentario:"O melhor é separar a roupa suja se ela não puder ser lavada imediatamente.",
            certa:false
         },
         {
            resposta:"De molho no álcool gel.",
            comentario:"O melhor é separar a roupa suja se ela não puder ser lavada imediatamente.",
            certa:false
         },
         {
            resposta:"Apenas os sapatos precisam ser higienizados.",
            comentario:"O melhor é separar a roupa suja se ela não puder ser lavada imediatamente.",
            certa:false
         }
      ],
      fonte:"https://portal.fiocruz.br/pergunta/e-preciso-ter-algum-cuidado-especial-com-roupas"
   },
   {
      pergunta:"Trabalho com público e não posso ficar em casa. Como devo me portar no atendimento?",
      opcoes:[
         {
            resposta:"Usando máscara.",
            comentario:"Todos os procedimentos devem ser atendidos: distanciamento, uso de máscara, higienização com álcool 70.",
            certa:false
         },
         {
            resposta:"Usando álcool gel.",
            comentario:"Todos os procedimentos devem ser atendidos: distanciamento, uso de máscara, higienização com álcool 70.",
            certa:false
         },
         {
            resposta:"Mantendo a distância mínima de dois metros.",
            comentario:"Todos os procedimentos devem ser atendidos: distanciamento, uso de máscara, higienização com álcool 70.",
            certa:false
         },
         {
            resposta:"Respeitando o protocolo completo de prevenção.",
            comentario:"Todos os procedimentos devem ser atendidos: distanciamento, uso de máscara, higienização com álcool 70.",
            certa:true
         }
      ],
      fonte:"https://portal.fiocruz.br/pergunta/como-se-prevenir-da-covid-19-tendo-que-sair-para-trabalhar-e-morando-em-casas-pequenas-com"
   },
   {
      pergunta:"Quais são os principais sintomas de alguém infectado com COVID-19?",
      opcoes:[
         {
            resposta:"Tontura e vômitos.",
            comentario:"Os sintomas são febre, dificuldade de respirar, tosse seca, perda de paladar ou olfato.",
            certa:false
         },
         {
            resposta:"Urticária e manchas no corpo.",
            comentario:"Os sintomas são febre, dificuldade de respirar, tosse seca, perda de paladar ou olfato.",
            certa:false
         },
         {
            resposta:"Febre, dificuldade de respirar, tosse seca, perda de paladar ou olfato.",
            comentario:"Correta. Observando que algumas pessoas são infectadas, mas não apresentam sintomas.",
            certa:true
         },
         {
            resposta:"Dor nas articulações e dificuldade de locomoção.",
            comentario:"Os sintomas são febre, dificuldade de respirar, tosse seca, perda de paladar ou olfato.",
            certa:false
         }
      ],
      fonte:"​OMS"
   },
   {
      pergunta:"É possível pegar Covid-19 de uma pessoa que não apresenta sintomas aparentes?",
      opcoes:[
         {
            resposta:"Sim",
            comentario:"Qualquer um pode pegar pegar Covid-19 de alguém que não se sente doente.",
            certa:true
         },
         {
            resposta:"Somente idosos.",
            comentario:"Qualquer um pode pegar pegar Covid-19 de alguém que não se sente doente.",
            certa:false
         },
         {
            resposta:"Não.",
            comentario:"Qualquer um pode pegar pegar Covid-19 de alguém que não se sente doente.",
            certa:false
         },
         {
            resposta:"Somente quem já está com outra doença.",
            comentario:"Qualquer um pode pegar pegar Covid-19 de alguém que não se sente doente.",
            certa:false
         }
      ],
      fonte:"​OMS"
   },
   {
      pergunta:"Como fazer compras na rua com segurança?",
      opcoes:[
         {
            resposta:"Lavando os alimentos quando chegar em casa.",
            comentario:"Na rua, mantendo distanciamento, usando máscara e álcool 70. Em casa, lavando alimentos e roupas.",
            certa:false
         },
         {
            resposta:"Na rua, mantendo distanciamento, usando máscara e álcool 70. Em casa, lavando alimentos e roupas.",
            comentario:"Evite tocar os olhos, boca e nariz e tome banho ao chegar em casa.",
            certa:true
         },
         {
            resposta:"Testando a temperatura na porta do comércio e usando máscara.",
            comentario:"Na rua, mantendo distanciamento, usando máscara e álcool 70. Em casa, lavando alimentos e roupas.",
            certa:false
         },
         {
            resposta:"Somente se o local estiver vazio.",
            comentario:"Na rua, mantendo distanciamento, usando máscara e álcool 70. Em casa, lavando alimentos e roupas.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Posso usar uma receita de gel para fazer em casa?",
      opcoes:[
         {
            resposta:"Não. Esse processo de produção caseira pode, inclusive, ser prejudicial à saúde.",
            comentario:"Para ser desinfetante o gel deve conter uma concentração de álcool 70%.",
            certa:true
         },
         {
            resposta:"Sim, álcool comum e gel de cabelo são eficazes.",
            comentario:"Para ser desinfetante o gel deve conter uma concentração de álcool 70%.",
            certa:false
         },
         {
            resposta:"Sim, o uso de vinagre substitui o álcool.",
            comentario:"O vinagre não substitui o gel, que para ser desinfetante deve conter uma concentração de álcool 70%.",
            certa:false
         },
         {
            resposta:"Água quente e gelatina podem ser usados.",
            comentario:"Para ser desinfetante o gel deve conter uma concentração de álcool 70%.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Mulheres com suspeita ou confirmação de Covid -19 podem amamentar?",
      opcoes:[
         {
            resposta:"Sim.",
            comentario:"Mantendo os cuidados de lavar as mãos e uso de máscara. Os benefícios superam os riscos.",
            certa:true
         },
         {
            resposta:"Não.",
            comentario:"Mantendo os cuidados de lavar as mãos e uso de máscara. Os benefícios superam os riscos.",
            certa:false
         },
         {
            resposta:"Sim, no caso de mães até 30 anos.",
            comentario:"Mantendo os cuidados de lavar as mãos e uso de máscara, mães de qualquer idade podem amamentar.",
            certa:false
         },
         {
            resposta:"Somente bebês acima de 1 mês de vida.",
            comentario:"A mãe pode amamentar, usando máscara, bebês de qualquer idade.",
            certa:false
         }
      ],
      fonte:"​OMS"
   },
   {
      pergunta:"Quem que já usa medicação para outros tratamentos deve parar por ter Covid-19?",
      opcoes:[
         {
            resposta:"Não.",
            comentario:"Antes de interromper qualquer medicação ou tratamento, o paciente deve consultar um médico.",
            certa:true
         },
         {
            resposta:"Somente pessoas jovens devem interromper.",
            comentario:"Antes de interromper qualquer medicação ou tratamento, o paciente deve consultar um médico.",
            certa:false
         },
         {
            resposta:"Sim.",
            comentario:"Antes de interromper qualquer medicação ou tratamento, o paciente deve consultar um médico.",
            certa:false
         },
         {
            resposta:"No caso demora da recuperação.",
            comentario:"Antes de interromper qualquer medicação ou tratamento, o paciente deve consultar um médico.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Por que a doença causada pelo novo vírus recebeu o nome de Covid-19?",
      opcoes:[
         {
            resposta:"Significa Corona Virus Disease e “19” se refere a 2019, quando os primeiros casos foram diagnosticados.",
            comentario:"Correto​.",
            certa:true
         },
         {
            resposta:"Covid-19 significa Contágio de Codificação Viral e os primeiros óbitos foram em 2019.",
            comentario:"Significa Corona Virus Disease e “19” se refere a 2019, quando os primeiros casos foram diagnosticados.",
            certa:false
         },
         {
            resposta:"Covid-19 significa um grupo de vírus da família do HIV e descoberto em 2019.",
            comentario:"Significa Corona Virus Disease e “19” se refere a 2019, quando os primeiros casos foram diagnosticados.",
            certa:false
         },
         {
            resposta:"COVID-19 significa um agente infeccioso muito pequeno composto por 19 tipos de vírus.",
            comentario:"Significa Corona Virus Disease e “19” se refere a 2019, quando os primeiros casos foram diagnosticados.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Máscara caseira feita com perfex funciona?",
      opcoes:[
         {
            resposta:"Não.",
            comentario:"O perfex é poroso e não protege contra o vírus, que é microscópico.",
            certa:true
         },
         {
            resposta:"Sim, porque é mais fácil para respirar.",
            comentario:"O perfex é poroso e não protege contra o vírus, que é microscópico.",
            certa:false
         },
         {
            resposta:"Qualquer máscara funciona.",
            comentario:"O perfex é poroso e não protege contra o vírus, que é microscópico.",
            certa:false
         },
         {
            resposta:"Se trocada de 3h em 3h funciona.",
            comentario:"O perfex é poroso e não protege contra o vírus, que é microscópico.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Insetos podem transmitir Covid-19?",
      opcoes:[
         {
            resposta:"Não.",
            comentario:"Até o momento não foi documentada a transmissão do novo coronavírus por insetos.",
            certa:true
         },
         {
            resposta:"Somente o mosquito da dengue.",
            comentario:"Até o momento não foi documentada a transmissão do novo coronavírus por insetos.",
            certa:false
         },
         {
            resposta:"Sim qualquer inseto pode transmitir o vírus.",
            comentario:"Até o momento não foi documentada a transmissão do novo coronavírus por insetos.",
            certa:false
         },
         {
            resposta:"Sim para pessoas do grupo de risco.",
            comentario:"Até o momento não foi documentada a transmissão do novo coronavírus por insetos.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Por que a demora do desenvolvimento da vacina para o Covid-19?",
      opcoes:[
         {
            resposta:"Falta de interesse das autoridades.",
            comentario:"A produção de uma vacina precisa cumprir várias etapas científicas. Além disso, vírus sofrem mutações.",
            certa:false
         },
         {
            resposta:"Porque a produção de uma vacina precisa cumprir várias etapas científicas. Além disso, vírus sofrem mutações.",
            comentario:"Correto.",
            certa:true
         },
         {
            resposta:"Não existem profissionais capacitados no Brasil.",
            comentario:"A produção de uma vacina precisa cumprir várias etapas científicas. Além disso, vírus sofrem mutações.",
            certa:false
         },
         {
            resposta:"Porque é muito caro o estudo e vacinas não garantem imunidade.",
            comentario:"A produção de uma vacina precisa cumprir várias etapas científicas. Além disso, vírus sofrem mutações.",
            certa:false
         }
      ],
      fonte:"​Ministério da Saúd​e"
   },
   {
      pergunta:"Bia está no mercado. O celular toca, ela tira a máscara para atender e passa para a amiga. Está certo?",
      opcoes:[
         {
            resposta:"Claro, é amiga dela.",
            comentario:"Jamais tire a máscara fora de casa nem compartilhe objetos pessoais.",
            certa:false
         },
         {
            resposta:"Lógico, ela conhece a pessoa.",
            comentario:"Jamais tire a máscara fora de casa nem compartilhe objetos pessoais.",
            certa:false
         },
         {
            resposta:"Não, não se deve abaixar a máscara nem compartilhar objetos pessoais.",
            comentario:"Certo. Jamais tire a máscara fora de casa nem compartilhe objetos pessoais.",
            certa:true
         },
         {
            resposta:"Sim, não dá para falar no cel com máscara né?!",
            comentario:"Jamais tire a máscara fora de casa nem compartilhe objetos pessoais.",
            certa:false
         }
      ],
      fonte:"Fiocruz​"
   },
   {
      pergunta:"A entrega chegou no prédio de João e ele não usou máscara para buscar no portão. Você faria o mesmo?",
      opcoes:[
         {
            resposta:"Jamais, o elevador é fonte de contaminação.",
            comentario:"Elevadores são fontes de contaminação. E pode haver contato com o entregador e moradores.",
            certa:true
         },
         {
            resposta:"Lógico, seria tão rápido, não haveria problema.",
            comentario:"Elevadores são fontes de contaminação. E pode ocorrer contato com o entregador e outros moradores.",
            certa:false
         },
         {
            resposta:"Sim, só tem problema sair do prédio.",
            comentario:"Elevadores são fontes de contaminação. E pode ocorrer contato com o entregador e outros moradores.",
            certa:false
         },
         {
            resposta:"Claro, meus vizinhos são pessoas boas e conheço o entregador, eles não tem Covid.",
            comentario:"Elevadores são fontes de contaminação. E pode ocorrer contato com o entregador e outros moradores.",
            certa:false
         }
      ],
      fonte:"​Fiocruz​ e ​UFMG"
   },
   {
      pergunta:"Lucas encontrou Mari e eles se abraçaram para matar saudades, já que a menina estava de máscara. Está certo?",
      opcoes:[
         {
            resposta:"Ainda não é hora de ficar tão próximo.",
            comentario:"A hora é de isolamento social. A máscara diminui o contágio mas não o impede. Manter distância de 2 metros é o ideal.",
            certa:true
         },
         {
            resposta:"Certo, se um está de máscara não há problemas.",
            comentario:"Ainda que os dois estivessem de máscara a distância mínima de dois metros deve ser mantida.",
            certa:false
         },
         {
            resposta:"Claro, se há máscara não tem problema.",
            comentario:"A máscara é muito importante mas a distância mínima de 2 metros deve ser mantida.",
            certa:false
         },
         {
            resposta:"Certo, abraçar pode, só não pode beijar.",
            comentario:"Ainda que os dois estivessem de máscara a distância mínima de dois metros deve ser mantida.",
            certa:false
         }
      ],
      fonte:"​Ministério da Saúde"
   },
   {
      pergunta:"Sou jovem e saudável e não vivo com idosos então posso sair sem me preocupar?",
      opcoes:[
         {
            resposta:"A Covid não escolhe idade e além disso você pode contaminar outras pessoas.",
            comentario:"Exato, há vários casos confirmados, em alguns infelizmente faleceram, fora dos grupos de risco.",
            certa:true
         },
         {
            resposta:"Claro, sou atleta, não corro risco ter complicações.",
            comentario:"Há vários casos confirmados fora dos grupos de risco e você ainda pode contaminar outros.",
            certa:false
         },
         {
            resposta:"Com certeza, me alimento bem, faço academia, isso é doença pra velho.",
            comentario:"Há vários casos confirmados fora dos grupos de risco e você ainda pode contaminar outros.",
            certa:false
         },
         {
            resposta:"Sim, basta só usar álcool em gel.",
            comentario:"Há vários casos confirmados fora dos grupos de risco e você ainda pode contaminar outros.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Quem já teve Covid está imune?",
      opcoes:[
         {
            resposta:"Claro, não se pega duas vezes.",
            comentario:"Ainda não há comprovação científica, então é importante continuar se protegendo e aos demais.",
            certa:false
         },
         {
            resposta:"Não, ainda não há garantia.",
            comentario:"Ainda não há comprovação científica, então é importante continuar se protegendo e aos demais.",
            certa:true
         },
         {
            resposta:"Sim, já posso sair livremente.",
            comentario:"Ainda não há comprovação científica, então é importante continuar se protegendo e aos demais.",
            certa:false
         },
         {
            resposta:"Lógico, posso ficar tranquila, é como certas doenças infantis, só se pega uma vez.",
            comentario:"Ainda não há comprovação científica, então é importante continuar se protegendo e aos demais.",
            certa:false
         }
      ],
      fonte:"Fiocruz"
   },
   {
      pergunta:"Estou há uma semana sem sair de casa e sem contato com entregadores. Estou sem o vírus?",
      opcoes:[
         {
            resposta:"Não, pois o tempo previsto para aparecimento de sintomas é de até 14 dias.",
            comentario:"Correto! O tempo previsto para aparecimento de sintomas é de até 14 dias.",
            certa:true
         },
         {
            resposta:"Sim, já se passaram sete dias.",
            comentario:"O tempo previsto para aparecimento de sintomas é de até 14 dias.",
            certa:false
         },
         {
            resposta:"Sim, se tivesse pego a doença teria sentido os sintomas em dois dias.",
            comentario:"O tempo previsto para aparecimento de sintomas é de até 14 dias.",
            certa:false
         },
         {
            resposta:"Sim, afinal estou sem febre e respirando normalmente.",
            comentario:"O tempo previsto para aparecimento de sintomas é de até 14 dias.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Camila começou a tossir e colocou as mãos na boca. Você faria o mesmo?",
      opcoes:[
         {
            resposta:"Lógico, tenho que proteger as pessoas.",
            comentario:"Errado! As mãos contêm impurezas. Deve-se usar um lenço ou o antebraço para cobrir a boca.",
            certa:false
         },
         {
            resposta:"Não, ​deve-se usar um lenço ou o antebraço para cobrir a boca.",
            comentario:"Isso aí! Parabéns!!",
            certa:true
         },
         {
            resposta:"Com certeza, sempre deve-se colocar a mão na boca ao tossir.",
            comentario:"Errado! As mãos contêm impurezas. Deve-se usar um lenço ou o antebraço para cobrir a boca.",
            certa:false
         },
         {
            resposta:"Sim, as mãos substituem a máscara.",
            comentario:"Errado! As mãos contêm impurezas. Deve-se usar um lenço ou o antebraço para cobrir a boca.",
            certa:false
         }
      ],
      fonte:"​Fiocruz"
   },
   {
      pergunta:"Ivo fez compras no mercado e achou que não precisava lavar já que ele mesmo pegou os produtos. Está certo?",
      opcoes:[
         {
            resposta:"Sim, se ele que pegou não precisa limpar nada.",
            comentario:"Ao chegar em casa todos os produtos devem ser limpos, inclusive mãos, roupas e calçados de Ivo.",
            certa:false
         },
         {
            resposta:"Claro, se ele foi de máscara não tem problema.",
            comentario:"Ao chegar em casa todos os produtos devem ser limpos, inclusive mãos, roupas e calçados de Ivo.",
            certa:false
         },
         {
            resposta:"Errado, todas as precauções devem ser tomadas, como máscara, manter distância e limpar as compras ao chegar.",
            comentario:"Certo. Ao chegar em casa todos os produtos devem ser limpos, inclusive mãos, roupas e calçados de Ivo.",
            certa:true
         },
         {
            resposta:"Certo, só precisa limpar se pedir no delivery.",
            comentario:"Ao chegar em casa todos os produtos devem ser limpos, inclusive mãos, roupas e calçados de Ivo.",
            certa:false
         }
      ],
      fonte:"Fiocruz e Fiocruz"
   },
   {
      pergunta:"Ana recebeu uma receita de alho com própolis que cura Covid-19. Ela está protegida do vírus?",
      opcoes:[
         {
            resposta:"Sim, pois própolis é antibiótico.",
            comentario:"Não, porque não há tratamento comprovado contra a Covid-19 e porque é preciso checar um conteúdo que pode ser falso.",
            certa:false
         },
         {
            resposta:"Não, pois o alho só funciona se for frito.",
            comentario:"Não, porque não há tratamento comprovado contra a Covid-19 e porque é preciso checar um conteúdo que pode ser falso.",
            certa:false
         },
         {
            resposta:"Sim, qualquer medicação de gripe ajuda na cura do Covid.",
            comentario:"Não, porque não há tratamento comprovado contra a Covid-19 e porque é preciso checar um conteúdo que pode ser falso.",
            certa:false
         },
         {
            resposta:"Não, não há cura ainda.",
            comentario:"Sim, porque não há tratamento comprovado contra a Covid-19 e porque é preciso checar um conteúdo que pode ser falso.",
            certa:true
         }
      ],
      fonte:"​Fiocruz"
   }
];