import React, { Component } from 'react';
import { coronaQuestions } from './api/coronaQuestions';
import Pergunta from './components/Pergunta';
import Resultado from './components/Resultado';
import Comentario from './components/Comentario';
import Home from './components/Home';
import Creds from './components/Creds';

// import HamburgerMenu from 'react-hamburger-menu';

/* image imports */
import bg from './img/bg.png';


/* css imports */ 
import './App.css';
import "bootstrap/dist/css/bootstrap.css";

const SHUFFLE_RESPOSTAS = true; // embaralha as respostas
const DRAW_PERGUNTAS = 6; // use false para não sortear perguntas
const LIMITE_TEMPO = 90000; //um minuto e meio em milliseconds - 90000

class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      counter: 0,
      num_pergunta: 1,
      pergunta: '',
      opcoes: [],
      idEscolhida: -1,
      comentario: '',
      num_certas: 0,
      total_perguntas: 0,
      fonte: '',
      rota: 'home',
      inicio: '',
      isMenuOpen: false
    };

    this.handleEscolhaOpcao = this.handleEscolhaOpcao.bind(this);
    this.handleProximaPergunta = this.handleProximaPergunta.bind(this);
    this.handleIniciar = this.handleIniciar.bind(this);
    this.handleVoltar = this.handleVoltar.bind(this);
    this.handleSobre = this.handleSobre.bind(this);

    if (DRAW_PERGUNTAS){
      const indices = this.draw_indices(DRAW_PERGUNTAS, coronaQuestions);
      this.questoes = coronaQuestions.filter((el, index) => this.filtra_perguntas(index, indices));
    } else {
      this.questoes = coronaQuestions;
    }

  }

  componentDidMount() {
    if (SHUFFLE_RESPOSTAS){
      this.coronaOpcoes = this.questoes.map((pergunta) => this.shuffleArray(pergunta.opcoes));
    } else {
      this.coronaOpcoes = this.questoes.map((pergunta) => pergunta.opcoes);
    }
    
  
    this.setState({
      pergunta: this.questoes[0].pergunta,
      opcoes: this.coronaOpcoes[0],
      fonte: this.questoes[0].fonte
    });
  }

  shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  };

  draw_indices(quantidade, array){
    let indices = [];
    let sorteado;
    while (indices.length < quantidade){
      sorteado = Math.floor(Math.random() * array.length);
      if (indices.indexOf(sorteado) === -1){
        indices.push(sorteado);
      }
    }
    return indices;
  }

  filtra_perguntas(index, indices){
    return indices.indexOf(index) !== -1;
  }

  setUserAnswer(indice_resposta) {
    let certas;
    if (this.state.opcoes[indice_resposta].certa) {
      certas = this.state.num_certas + 1
    } else {
      certas = this.state.num_certas
    }
    this.setState({
      num_certas: certas,
      idEscolhida: Number(indice_resposta),
      comentario: this.state.opcoes[indice_resposta].comentario,
      rota: 'comentario'
    });
  }

  setNextQuestion(){
    const counter = this.state.counter + 1;
    const num_pergunta = this.state.num_pergunta + 1;
    this.setState({
      counter: counter,
      num_pergunta: num_pergunta,
      pergunta: this.questoes[counter].pergunta,
      opcoes: this.coronaOpcoes[counter],
      fonte: this.questoes[counter].fonte,
      idEscolhida: -1,
      rota: 'pergunta'
    });
  }

  handleIniciar(){
    this.setState({
      rota: 'pergunta', 
      inicio: Date.now(), 
      estourou_tempo: false,
      controla_tempo: window.setInterval(
        () => {
          if (Date.now() - this.state.inicio > LIMITE_TEMPO){
            clearInterval(this.state.controla_tempo);
            this.setState({
              estourou_tempo: true,
              rota: 'resultado'});
          }
        }
      , 1000)
     }); // inicia o jogo e registra o momento no state.inicio
  }

  handleVoltar(){
    this.setState({rota: 'home'});
  }

  handleSobre(){
    this.setState({rota: 'sobre'});
  }

  handleProximaPergunta(event){
    // verifica se acertaram-se 4 pergutnas, se ainda existem perguntas para responder e se o tempo não terminou
    if ((this.state.num_certas < 4) && (this.state.num_pergunta < this.questoes.length) ){
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      clearInterval(this.state.controla_tempo);
      setTimeout(() => this.getResultados(), 300);
    }
  }

  getResultados(){
    this.setState({
      rota:'resultado'
    })
  }

  handleEscolhaOpcao(event){
    const idEscolhida = event.currentTarget.value;
    setTimeout(() => this.setUserAnswer(idEscolhida), 300);
  }

  
  rotaSwitch(rota){
    if (rota === 'home') {
      return (
        <Home iniciar={this.handleIniciar} sobre={this.handleSobre}/>
      )
    } else if (rota === 'sobre') {
      return (
        <Creds voltar={this.handleVoltar}/>
      )
    } else if (rota === 'pergunta'){
      return(
        <Pergunta
          counter={this.state.counter}
          num_pergunta={this.state.num_pergunta}
          pergunta={this.state.pergunta}
          opcoes={this.state.opcoes}
          id={this.state.num_pergunta}
          idEscolhida={this.state.idEscolhida}
          questionTotal={this.questoes.length}
          onAnswerSelected={this.handleEscolhaOpcao}
        />
      );
    } else if (rota === 'comentario'){
      return(
        <Comentario
          comentario={this.state.comentario}
          fonte={this.state.fonte}
          proxima={this.handleProximaPergunta}
          certa={this.state.opcoes[this.state.idEscolhida].certa}
          num_certas={this.state.num_certas}
          total={DRAW_PERGUNTAS}
        />
      );
    } else if (rota === 'resultado'){
      return(
        <Resultado num_certas={this.state.num_certas} sobre={this.handleSobre} estourou={this.state.estourou_tempo}/>
      );
    }
  }

  handleClick() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  handleCredsButton() {
    this.setState({
      rota:'sobre'
    })

  }

  render(){
    return (
      <div className="App">
        <div className="container">
          {this.rotaSwitch(this.state.rota)}
        </div>
        <img className="background-image" src={bg} alt="bg" />
      </div>
    );
  }
}

export default App;
