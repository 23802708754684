import React from 'react';
import PropTypes from 'prop-types';
import logo from '../img/logo.png';
import playButton from '../img/play_button.png';

const Home = (props) => {
    return(
        <div className="home">
            <button className='sobre-button' onClick={props.sobre}>SOBRE</button>
            <div style={{clear: 'both'}}></div>
            <img className="logo" src={logo} alt="logo"/> <br />
            <img className="button button-home" src={playButton} onClick={props.iniciar} alt="Jogar"></img>
            <br />
            <p className="home-text">Acerte 4 dentre 6 questões e apague o Coronavírus.</p>
            <p className="home-text">Você tem 1 minuto e meio.</p>
            <p className="home-text"><span className="home-convite">VAMOS LÁ?</span></p>
            
        </div>
    )
};

Home.propTypes = {
    iniciar: PropTypes.func.isRequired,
    sobre: PropTypes.func.isRequired
};

export default Home;